import Vue from "vue";
import VueRouter from "vue-router";
import "../plugins/fontawesome.js";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: () => import("../views/Offline.vue") },
  { path: "/404", component: () => import("../views/404.vue") },
  { path: "/referral", redirect: "/404" },
  { path: "*", redirect: "https://gaimin.gg?deviceId=33697" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
